

<template>
  <div class="stationView">
    <main class="player-content flex-row">
      <section class="flex-1">
        <div class="flex-autorow flex-top flex-stretch player-channel">
          <!-- station details -->

<main-song :ArtisteEnCours="ArtisteEnCours" :TitreEnCours="TitreEnCours" :PochetteEnCours="PochetteEnCours"></main-song>
          <!-- songs list  <songs-history></songs-history>-->

        </div>

        <!--<syncLyrics></syncLyrics>-->

      </section>
    </main>

  </div>
</template>

<script>
    /**
     * Home
     */
    import {mapGetters, mapState, mapActions} from 'vuex';
    import favBtn from "@/views/components/favBtn";
    import SongsHistory from "@/views/station/components/songsHistory";
    import MainSong from "@/views/station/components/mainSong";
    import footerPlayer from '@/views/station/components/footerPlayer'

    export default {
        name: 'home',
        components: {
            favBtn,
            MainSong,
            SongsHistory,
            footerPlayer
        },
        data: () => {
            return {
                // toggles
                visible: false,
                sidebar: false,
                errors: {},

            }
        },
    props: {
    ArtisteEnCours: String,
    TitreEnCours: String,
    PochetteEnCours: String,
  },
  // watch methods
  watch: {
    currentsong() {
      this.updateMediaSession();
    },
    //route change
    $route(to, from) {
    },
    // update player volume

  },

        // custom methods
        methods: {
         
            toggleSidebar() {
                this.$parent.toggleSidebar(true);
            },
                //Update navigator media session data

        },

        // on app mounted
        mounted() {
            console.log("mounted Home.vue");
            this.$parent.init = true;
        },

        // on app destroyed
        destroyed() {
        }

    }
</script>